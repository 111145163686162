<template>
  <div v-clickaway="hide" class="relative inline-block h-full">
    <div class="h-full flex items-center" @click="toggle">
      <slot :is-visible="isVisible" />
      <BaseIcon
        v-if="showArrow"
        name="outline_chevron_down"
        class="flex-shrink-0 opacity-20 ml-2 cursor-pointer transform transition duration-100 ease-in-out"
        :class="isVisible && 'rotate-180'"
        size="md"
      />
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <div
        v-show="isVisible"
        ref="menu"
        class="absolute"
        :class="[
          proxyLevel,
          proxyDropDownWidth,
          canOpenLeft ? 'right-0' : 'left-0',
          canOpenBottom ? (withOffset ? 'top-full' : 'top-0') : 'bottom-full',
          `origin-${canOpenBottom ? 'top' : 'bottom'}-${
            canOpenLeft ? 'right' : 'left'
          }`,
        ]"
        @click="onClick"
      >
        <slot name="menu" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

const dropDownWidth = {
  xs: 'max-w-xs',
  sm: 'max-w-sm',
  md: 'max-w-md',
  lg: 'max-w-lg',
  xl: 'max-w-xl',
  '2xl': 'max-w-2xl',
  '3xl': 'max-w-3xl',
  '4xl': 'max-w-4xl',
  '5xl': 'max-w-5xl',
  '6xl': 'max-w-6xl',
  '7xl': 'max-w-7xl',
  full: 'w-full',
}

export default defineComponent({
  name: 'BaseMenu',
  props: {
    parent: {
      type: Object as PropType<HTMLElement>,
      default: undefined,
    },
    level: {
      type: [String, Number],
      default: 10,
    },
    hideOnClick: {
      type: Boolean,
      default: true,
    },
    dropDownWidth: {
      type: String as PropType<keyof typeof dropDownWidth>,
      default: 'xs',
    },
    withOffset: {
      type: Boolean,
      default: true,
    },
    showArrow: {
      type: Boolean,
      default: false,
    },
    preferBottom: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      canOpenLeft: false,
      canOpenBottom: false,
      isVisible: false,
    }
  },
  computed: {
    proxyLevel() {
      return this.level && `z-${this.level}`
    },
    proxyDropDownWidth(): string | undefined {
      return this.dropDownWidth && dropDownWidth[this.dropDownWidth]
    },
  },
  methods: {
    toggle() {
      if (this.isVisible) {
        this.isVisible = false
      } else {
        this.isVisible = true

        this.$nextTick(() => {
          this.checkPositionX()
          this.checkPositionY()
        })
      }
    },
    hide() {
      this.isVisible = false
    },
    checkPositionX() {
      const rectEl = this.$el.getBoundingClientRect()
      const menu = this.$refs.menu as HTMLElement
      const rectMenu = menu.getBoundingClientRect()

      if (this.parent) {
        const rectParent = this.parent.getBoundingClientRect()

        this.canOpenLeft = rectEl.left - rectParent.left > rectMenu.width
      } else {
        this.canOpenLeft = rectEl.left > rectMenu.width
      }
    },
    checkPositionY() {
      const rectEl = this.$el.getBoundingClientRect()
      const menu = this.$refs.menu as HTMLElement
      const rectMenu = menu.getBoundingClientRect()

      if (this.parent) {
        const rectParent = this.parent.getBoundingClientRect()

        this.canOpenBottom =
          rectEl.top + rectMenu.height + 50 < rectParent.bottom
      } else {
        const canOpenUp = rectEl.top > rectMenu.height + 50
        const canOpenBottom =
          window.innerHeight - rectEl.bottom > rectMenu.height + 50

        if ((canOpenUp && canOpenBottom) || (!canOpenUp && !canOpenBottom)) {
          this.canOpenBottom = this.preferBottom
        } else {
          this.canOpenBottom = this.preferBottom ? canOpenBottom : !canOpenUp
        }
      }
    },
    onClick() {
      if (this.hideOnClick) {
        this.hide()
      }
    },
  },
})
</script>
